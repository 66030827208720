import { GetApiData } from "utils/http-client";


export const GetAllExercises = function (page, limit, search) {
    return GetApiData(`/exercise?page=${page}&limit=${limit}&search=${search}&status=pending`, 'GET', null, true);
}
export const UpdateExerciseStatus = async (id, newStatus) => {
    return await GetApiData(`/request/manage/${id}`, 'PUT', { status: newStatus }, true);
};
export const ChangeExerciseLibraryStatus = async (id, { status, message }) => {
    return await GetApiData(`/exercise/library/manage/${id}`, 'PUT', { status, message: message || null }, true);
};
export const getExerciseDetail = async (id) => {
    return await GetApiData(`/request/${id}`, 'GET', null, true);
};
export const getLibraryExerciseDetail = async (id) => {
    return await GetApiData(`/exercise/${id}`, 'GET', null, true);
};
export const getLibraryExerciseRatingReviews = async (id) => {
    return await GetApiData(`/exercise/rating/${id}`, 'GET', true);
};
export const getLibraryExerciseRating = async (data, id) => {
    return await GetApiData(`/exercise/rating/${id}`, 'POST', data, true);
};
export const duplicateLibraryForUser = async (id) => {
    return await GetApiData(`/exercise/duplicate/${id}`, 'POST', null, true);
};