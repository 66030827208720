import React, { useEffect, useState } from "react";
import { Button, Form, Image, InputGroup, Table } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import nodataimage from "../../../assets/nodataimage.svg";
import { faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeading from "components/shared/PageHeading";
import CustomPagination from "components/shared/Pagination";
import ReasonModal from "components/modals/ReasonModal";
import { getAllAssignment } from "services/Teacher/Assignments.service";
import moment from "moment";
import { createSubmission, generateSebFile } from "services/Student/Submission.services";
import DynamicModal from "components/modals/DynamicModal";
import SebInstructionsModal from "components/modals/SebInstructionsModal";
import AssignmentFilter from "./AssignmentFilter";

const Assignments = ({ source = null, classId = null }) => {

  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const initialPage = parseInt(searchParams.get("page"), 10) || 1;
  const initialLimit = parseInt(searchParams.get("limit"), 10) || 5;
  const initialSearchQuery = searchParams.get("search") || "";

  const [exercises, setExercises] = useState([]);
  const [loadingSeb, setLoadingSeb] = useState(false);
  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
  const [search, setSearch] = useState(initialSearchQuery);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showSEBModal, setShowSEBModal] = useState(false);
  const [showTerminatedModal, setShowTerminatedModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const [currentSubId, setCurrentSubId] = useState(null);
  const [filters, setFilters] = useState({});
  const [show, setShow] = useState(false);

  const limit = initialLimit;

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(!show);
  const AllExercises = async (page = 1, limit = 2, search = "") => {
    setLoading(true);
    try {
      const response = await getAllAssignment(
        page,
        limit,
        search,
        source,
        classId
      );
      if (response.data && response.data.results) {
        setExercises(response.data.results?.assignments || []);
        setTotalPages(response.data.results.totalPages || 1);
      } else {
        setExercises([]);
        setTotalPages(1);
      }
    } catch (error) {
      console.error("Error fetching exercises:", error);
      setExercises([]);
      setTotalPages(1);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    AllExercises(currentPage, limit, searchQuery);
  }, [currentPage, limit, searchQuery]);

  useEffect(() => {
    const params = new URLSearchParams({
      page: currentPage,
      limit,
      search: searchQuery,
    });
    navigate({ search: `?${params.toString()}` });
  }, [currentPage, limit, searchQuery, navigate]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchQuery(search);
    setCurrentPage(1);
  };
  const handleDownloadSebFile = async () => {
    setLoadingSeb(true);
    try {
      const response = await generateSebFile(currentId);

      if (response && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `quiz_${currentId}.seb`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        setShowSEBModal(false);
        setLoadingSeb(false);
        // alert("Safe Exam Browser quiz file has been downloaded.");
      } else {
        // throw new Error("Invalid response data");
        toast.error("Error While Downloading")
        setLoadingSeb(false);
      }
    } catch (error) {
      toast.error("Error While Downloading")
      console.error("Failed to download SEB file:", error);
      setLoadingSeb(false);
    }
  };
  const handleAction = (status, id, subId, type, studySetId, safeBrowser) => {
    console.log("Handling action for assignment", status, safeBrowser);
    setCurrentStatus(status);
    setCurrentId(id);
    setCurrentSubId(subId);
    if (status === "terminated") {
      setShowTerminatedModal(true); // Show the modal if the status is terminated
    } else if (["pending", "in-progress", "revise-needed","completed"].includes(status)) {
      if (type === "studySet") {
        navigate(`/vocabulary/assigned/${studySetId}?assignment=${id}`);
      }
      if (safeBrowser) {
        // handleDownloadSebFile(id);
        handleConfirm(safeBrowser);
      } else {
        setShowModal(true);
      }
    } else {
      // Directly call the relevant action for other statuses
      switch (status) {
        case "submitted":
          handleSubmittedAction(subId);
          break;
        case "marked":
          handleMarkedAction(subId);
          break;
        default:
          console.log("Unknown action");
      }
    }
  };
  const handleConfirm = (safeBrowser) => {
    console.log("Handle Confirm")
    switch (currentStatus) {
      case "pending":
        handlePendingAction(currentId, safeBrowser);
        break;
      case "in-progress":
        handleInProgressAction(currentSubId, safeBrowser);
        break;
      case "revise-needed":
        handleReviseAction(currentId, currentSubId, safeBrowser);
        break;
      default:
        console.log("Unknown action");
    }

    setShowModal(false);
  };
  const handlePendingAction = async (id, safeBrowser) => {
    console.log(`Handling pending action for assignment ${id}`);
    if (safeBrowser) {
      // handleDownloadSebFile(id)
      setShowSEBModal(true);
    } else {
      try {
        const response = await createSubmission({
          assignmentId: id,
        });
        console.log("response submission", response.data);
        navigate(`/quiz/attempt/${response.data?.result?._id}`);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleInProgressAction = async (id, safeBrowser) => {
    console.log(`Handling in-progress action for assignment ${id}`);
    console.log("Safe browser", safeBrowser)
    if (safeBrowser) {
      // handleDownloadSebFile(id)
      setShowSEBModal(true);
    } else {
      navigate(`/quiz/attempt/${id}`);
    }
  };

  const handleReviseAction = async (id, subId, safeBrowser) => {
    console.log(`Handling revise action for submission ${id}`);
    if (safeBrowser) {
      // handleDownloadSebFile(subId)
      setShowSEBModal(true);
    } else {
      await createSubmission(
        {
          assignmentId: id,
          submissionId: subId,
        },
        "revise-needed"
      );
      navigate(`/quiz/attempt/${subId}`);
    }

  };

  const handleSubmittedAction = (id) => {
    console.log(`Handling submitted action for assignment ${id}`);
    navigate(`/assignments/submission/${id}`);
  };

  const handleMarkedAction = (id) => {
    console.log(`Handling marked action for assignment ${id}`);
    navigate(`/assignments/submission/marked/${id}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const getLevelBackgroundColor = (level) => {
    switch (level) {
      case "pending":
        return "#C4C4C4";
      case "in-progress":
        return "#4DA9F4";
      case "submitted":
        return "#0FE133";
      case "marked":
        return "#EB3232";
      default:
        return "#B0BEC5";
    }
  };

  const getButtonLabel = (status) => {
    switch (status) {
      case "pending":
      case "in-progress":
        return "Attempt";
      case "submitted":
        return "View Submission";
      case "marked":
        return "View Remarks";
      case "revise-needed":
        return "Re-Attempt";
      case "terminated":
        return "View Remarks";
      default:
        return "Take Action";
    }
  };
  const applyFilters = (appliedFilters) => {
    setFilters(appliedFilters);
    setCurrentPage(1);

    const queryParams = new URLSearchParams({
      page: 1,
      limit,
      search,
      ...appliedFilters
    }).toString();

    navigate(`/assignments?${queryParams}`);
  };
  const handleSortChange = (field, order) => {
    // setSortField(field);
    // setSortOrder(order);
    setCurrentPage(1);
    navigate(`/assignments?page=1&limit=${limit}&sortField=${field}&sortOrder=${order}`);
  };
  return (
    <>
      <div className="d-block d-lg-flex justify-content-between">
        <div>
          <PageHeading heading={"Assignments"} />
        </div>
        <div className="d-block d-lg-flex gap-3 justify-content-between">
          <div>
            <Button
              className="px-4 py-2 w-100 w-lg-auto my-lg-0 my-2"
              onClick={handleShow}
              style={{
                color: "#858585",
                borderRadius: "30px",
                background: "white",
                border: "2px solid #858585",
                fontWeight: 600,
              }}
            >
              Sort By
              <FontAwesomeIcon icon={faFilter} className="ms-2" />
            </Button>
          </div>
          <div>
            <Form onSubmit={handleSearchSubmit}>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <InputGroup className="mb-3">
                  <Form.Control
                    type={"text"}
                    placeholder="Search Here..."
                    className="px-3 py-2 border-right-0"
                    value={search}
                    onChange={handleSearchChange}
                    style={{
                      borderTopLeftRadius: "30px",
                      borderBottomLeftRadius: "30px",
                      borderColor: "#ea4f50",
                      borderRight: "0",
                    }}
                  />
                  <InputGroup.Text
                    id="basic-addon2"
                    className="bg-white "
                    style={{
                      cursor: "pointer",
                      borderTopRightRadius: "30px",
                      borderBottomRightRadius: "30px",
                      borderColor: "#ea4f50",
                      borderLeft: "0",
                    }}
                    onClick={handleSearchSubmit}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <Table responsive="sm" style={{ overflow: "auto" }}>
          <thead>
            <tr>
              <th style={{ color: "#303972" }}>Assignment Name</th>
              <th style={{ color: "#303972" }}>Subject</th>
              <th style={{ color: "#303972" }}>Deadline</th>
              <th style={{ color: "#303972" }}>Status</th>
              <th style={{ color: "#303972" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              [...Array(limit)].map((_, index) => (
                <tr key={index}>
                  <td className="py-3">
                    <div
                      style={{
                        backgroundColor: "#e0e0e0",
                        height: "20px",
                        width: "80%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </td>
                  <td className="py-3">
                    <div
                      style={{
                        backgroundColor: "#e0e0e0",
                        height: "20px",
                        width: "40%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </td>
                  <td className="py-3">
                    <div
                      style={{
                        backgroundColor: "#e0e0e0",
                        height: "20px",
                        width: "40%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </td>
                  <td className="py-3">
                    <div
                      style={{
                        backgroundColor: "#e0e0e0",
                        height: "20px",
                        width: "40%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </td>
                  <td className="py-3">
                    <div
                      style={{
                        backgroundColor: "#e0e0e0",
                        height: "20px",
                        width: "40%",
                        borderRadius: "4px",
                      }}
                    ></div>
                  </td>
                </tr>
              ))
            ) : exercises.length === 0 ? (
              <tr>
                <td colSpan="5" className="text-center py-3">
                  <Image src={nodataimage} />
                  <p className="pt-2 text-secondary fw-bold fs-5">
                    No assignments found
                  </p>
                </td>
              </tr>
            ) : (
              exercises.map((exercise, index) => (
                <tr key={exercise._id}>
                  <td className="py-3">
                    <div
                      //   to={`attempt/${exercise?.exercise?._id}`}
                      style={{
                        color: "#303972",
                        fontWeight: 700,
                        textDecoration: "none",
                      }}
                    >
                      {exercise.title}
                    </div>
                  </td>
                  <td className="py-3" style={{ color: "#A098AE" }}>
                    {exercise?.exercise?.subject?.title ||
                      exercise?.studySet?.sourceLanguage?.title}
                  </td>
                  <td className="py-3" style={{ color: "#A098AE" }}>
                    {exercise?.endDate? moment(exercise?.endDate).format("MMM D, YYYY"): "No Deadline"}
                  </td>
                  <td className="py-3" style={{ color: "#A098AE" }}>
                    <span
                      style={{
                        display: "inline-block",
                        padding: "3px 10px",
                        borderRadius: "30px",
                        backgroundColor: getLevelBackgroundColor(
                          exercise?.submissionStatus
                        ),
                        color: "#fff",
                        fontSize: "14px",
                      }}
                    >
                      {exercise?.type === "studySet"
                        ? "ASSIGNED"
                        : new Date(exercise?.endDate) < new Date() &&
                          exercise?.submissionStatus !== "submitted"
                          ? "OVERDUE"
                          : exercise?.submissionStatus.toUpperCase()}
                    </span>
                  </td>
                  <td className="py-3">
                    <Button
                      variant="primary"
                      className="button-style border-0 py-1 px-3"
                      style={{
                        fontSize: "14px",
                      }}
                      disabled={
                        exercise?.type !== "studySet" &&
                        new Date(exercise?.endDate) < new Date() &&
                        exercise?.submissionStatus !== "submitted"
                      }
                      onClick={() =>
                        handleAction(
                          exercise?.submissionStatus,
                          exercise?._id,
                          exercise?.submissionId,
                          exercise?.type,
                          exercise?.type === "studySet"
                            ? exercise?.studySet._id
                            : null,
                          exercise?.safeBrowser
                        )
                      }
                    >
                      {exercise?.type === "studySet"
                        ? "View studyset"
                        : new Date(exercise?.endDate) < new Date() &&
                          exercise?.submissionStatus !== "submitted"
                          ? "Overdue"
                          : getButtonLabel(exercise?.submissionStatus)}
                    </Button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
      <CustomPagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      <DynamicModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={handleConfirm}
        heading="Assignment Instructions"
        description="If you switch tabs, minimize, or leave this tab, your assignment will be immediately terminated."
        confirmText="Start"
        denyText="Cancel"
      />
      <DynamicModal
        show={showTerminatedModal}
        handleClose={() => setShowTerminatedModal(false)}
        handleConfirm={() => setShowTerminatedModal(false)}
        heading={"Assignment Terminated"}
        cancelBtn={false}
        description={
          "You cheated or did some suspicious activity (like switching tabs or minimizing the window). As a result, your quiz has been canceled."
        }
        confirmText="Okay"
      />
      <SebInstructionsModal
        show={showSEBModal}
        handleClose={() => setShowSEBModal(false)}
        handleDownload={handleDownloadSebFile}
        loadingSeb={loadingSeb}
      />
      {/* <AssignmentFilter
        show={show}
        onClose={handleClose}
        onSortChange={handleSortChange}
        applyFilters={applyFilters}
      /> */}
    </>
  );
};

export default Assignments;
