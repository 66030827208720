import PageHeading from 'components/shared/PageHeading';
import Select from 'components/shared/Select';
import React, { useState, useEffect } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';  // Import styles for the skeleton
import { Placeholder } from 'react-bootstrap';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{
                backgroundColor: '#4D44B5',
                padding: '10px 30px',
                borderRadius: '8px',
                color: '#fff',
                border: '1px solid #444',
                textAlign: "center"
            }}>
                <p className='m-0'>{`${label}`}</p>
                {payload.map((entry, index) => (
                    <p className='m-0' key={index} style={{ color: entry.color, fontWeight: "700" }}>
                        {`${entry.name}: ${entry.value}%`}
                    </p>
                ))}
            </div>
        );
    }

    return null;
};

const Statistics = ({ statsData, loadingstats }) => {
    const [language, setLanguage] = useState("");
    const [subjects, setSubject] = useState("");

    // If statsData is loading, show skeleton loader
    if (loadingstats) {
        return (
            <>
                <div className="d-flex justify-content-between">
                    <div>
                        <PageHeading heading="Statistics" spanText='(Last 30 Days)' />
                    </div>
                    <div className='d-flex gap-2'>
                        <Select
                            value={subjects}
                            onChange={setSubject}
                            options={[{ value: '', label: 'Select Subject' }]}
                            ariaLabel="Select Subject"
                            required
                        />
                    </div>
                </div>
                <div className="my-3">
                    <Placeholder animation="wave">
                        <Placeholder xs={12} />
                    </Placeholder>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} />
                    </Placeholder>
                    <Placeholder as="p" animation="wave">
                        <Placeholder xs={12} />
                    </Placeholder>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} />
                    </Placeholder>
                    <Placeholder animation="wave">
                        <Placeholder xs={12} />
                    </Placeholder>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} />
                    </Placeholder>
                    <Placeholder as="p" animation="wave">
                        <Placeholder xs={12} />
                    </Placeholder>
                    <Placeholder as="p" animation="glow">
                        <Placeholder xs={12} />
                    </Placeholder>
                </div>
            </>
        );
    }

    const chartData = statsData.map(item => ({
        name: item.name,
        ...Object.keys(item).reduce((acc, key) => {
            if (key !== 'name') {
                acc[key] = item[key];
            }
            return acc;
        }, {})
    }));

    const subjectOptions = statsData.length > 0 ? Object.keys(statsData[0]).filter(key => key !== 'name').map(subject => ({
        value: subject,
        label: subject
    })) : [];

    const filteredData = subjects ? chartData.map(item => ({
        name: item.name,
        [subjects]: item[subjects]
    })) : chartData;

    const subjectColors = {
        English: '#FB7D5B',
        Swedish: '#FCC43E',
        French: '#6A5ACD',  // SlateBlue
        Spanish: '#FF6347',  // Tomato
        Italian: '#32CD32',  // LimeGreen
    };
    const legendPayload = subjects
        ? [{ value: subjects, type: "line", color: subjectColors[subjects] }]
        : subjectOptions.map(option => ({
            value: option.value,
            type: "line",
            color: subjectColors[option.value] || '#FB7D5B'
        }));
        return (
            <>
                <div className="d-flex justify-content-between">
                    <div>
                        <PageHeading heading="Statistics" spanText='(Last 30 Days)' />
                    </div>
                    <div className='d-flex gap-2'>
                        <Select
                            value={subjects}
                            onChange={setSubject}
                            options={[{ value: '', label: 'Select Subject' }, ...subjectOptions]}
                            ariaLabel="Select Subject"
                            required
                        />
                    </div>
                </div>
                <ResponsiveContainer className={"my-3"} width="100%" height={400}>
                    <AreaChart
                        data={filteredData}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 30,
                        }}
                    >
                        <defs>
                            {subjectOptions.map(option => (
                                <linearGradient key={option.value} id={`color${option.value}`} x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={subjectColors[option.value] || '#FB7D5B'} stopOpacity={0.8} />
                                    <stop offset="95%" stopColor={subjectColors[option.value] || '#FB7D5B'} stopOpacity={0} />
                                </linearGradient>
                            ))}
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis tickFormatter={(tick) => `${tick}%`} />
                        <Tooltip content={<CustomTooltip />} />
    
                        {subjectOptions
                            .filter(option => !subjects || option.value === subjects) // Show only the selected subject
                            .map(option => (
                                <Area
                                    key={option.value}
                                    type="monotone"
                                    dataKey={option.value}
                                    stackId="1"
                                    stroke={subjectColors[option.value] || '#FB7D5B'}
                                    fill={`url(#color${option.value})`}
                                    strokeWidth={6}
                                />
                            ))}
    
                        <Legend verticalAlign="bottom" align="center" payload={legendPayload} />
                    </AreaChart>
                </ResponsiveContainer>
            </>
        );
};

export default Statistics;
