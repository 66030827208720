import React, { useRef, useState } from "react";
import {
  Modal,
  Button,
  Form,
  Spinner,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { toast } from "react-toastify";
import {
  importStudentSheet,
  createBulkStudents,
} from "services/Teacher/ClassesAndGroups.service";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileDownload,
  faPlusCircle,
  faQuestionCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

// Simple unique id generator (for demo purposes)
const generateId = () => Date.now().toString() + Math.random().toString(36).substr(2, 5);

const ImportSheetModal = ({
  show,
  handleClose,
  handleShowCredentials,
  fetchUsers,
  classId,
  setSummaryData,
}) => {
  const [file, setFile] = useState(null);
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const gridRef = useRef(); // Create a reference for the grid

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleImport = async () => {
    if (!file) {
      toast.error("Please select a CSV or XLSX file.");
      return;
    }

    const formData = new FormData();
    formData.append("attachment", file);

    const toastId = toast.loading("Importing students...");
    setLoading(true);
    try {
      const response = await importStudentSheet(formData);
      // When importing, assign a unique id to each student
      const importedStudents =
        (response.data.results || []).map((student) => ({
          ...student,
          id: generateId(),
        })) || [];
      setStudents(importedStudents);
      toast.update(toastId, {
        render: "Students imported successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error importing students:", error);
      toast.update(toastId, {
        render: "Error importing students",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCreateStudents = async () => {

    
    
    if (students.length === 0) {
      toast.error("No students to create. Please import a sheet first.");
      return;
    }

    // Remove dummy row if present, then add classId to each student
    const formattedStudents = students.map((student) => ({
      FirstName: student.FirstName,
      LastName: student.LastName,
      email: student.email,
      password: student.password,
      classId,
    }));

    const toastId = toast.loading("Creating students...");
    setLoading(true);
    try {
      const response = await createBulkStudents({ students: formattedStudents });
      toast.update(toastId, {
        render: "Students created successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
      handleCloseModal();
      console.log("res", response.data.result);
      setSummaryData(response?.data?.result);
      handleShowCredentials();
      fetchUsers();
    } catch (error) {
      console.error("Error creating students:", error);
      toast.update(toastId, {
        render: "Error creating students",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRow = (id) => {
    const updatedStudents = students.filter((student) => student.id !== id);
    setStudents(updatedStudents);
  };

  // Insert a new student row at the end (before the dummy add row)
  const handleAddRow = () => {
    const newStudent = { id: generateId(), FirstName: "", LastName: "", email: "", password: "" };
    setStudents([...students, newStudent]);
  };

  // Custom renderer for the Actions column.
  // If the row is the dummy "add" row, show the plus button.
  // Otherwise, show the delete button.
  const actionCellRenderer = (params) => {
    if (params.data && params.data.isAddRow) {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <Button
            onClick={handleAddRow}
            className="bg-dark py-1 border-0"
            title="Add New Row"
          >
            <FontAwesomeIcon icon={faPlusCircle} style={{ cursor: "pointer", color: "white" }} />
          </Button>
        </div>
      );
    } else {
      const handleDelete = () => {
        handleDeleteRow(params.data.id);
      };

      return (
        <div className="d-flex align-items-center justify-content-center">
          <Button onClick={handleDelete} className="bg-dark py-1 border-0">
            <FontAwesomeIcon icon={faTrashAlt} style={{ cursor: "pointer", color: "red" }} />
          </Button>
        </div>
      );
    }
  };

  const columns = [
    {
      headerName: "First Name",
      field: "FirstName",
      editable: (params) => !params.data.isAddRow,
      cellEditorParams: { required: true },
    },
    {
      headerName: "Last Name",
      field: "LastName",
      editable: (params) => !params.data.isAddRow,
      cellEditorParams: { required: false },
    },
    {
      headerName: "Email",
      field: "email",
      editable: (params) => !params.data.isAddRow,
      cellEditorParams: { required: true },
    },
    {
      headerName: "Password",
      field: "password",
      editable: (params) => !params.data.isAddRow,
      cellEditorParams: { required: true, minLength: 8, maxLength: 20 },
    },
    {
      headerName: "Actions",
      field: "actions",
      flex: 1,
      cellRenderer: actionCellRenderer,
    },
  ];

  // Merge the students array with a dummy row at the end for adding new rows.
  const gridData = [...students, { isAddRow: true, id: "dummy" }];

  const validateData = () => {
    const emailSet = new Set();
    for (const student of students) {
      if (!student.FirstName || !student.email || !student.password) {
        toast.error("First Name, Email, and Password are required for each student.");
        return false;
      }
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(student.email)) {
        toast.error("Invalid email format for " + student.email);
        return false;
      }
      if (student.password.length < 8 || student.password.length > 20) {
        toast.error("Password must be between 8 and 20 characters for " + student.email);
        return false;
      }
      if (emailSet.has(student.email)) {
        toast.error("Duplicate email found: " + student.email);
        return false;
      }
      emailSet.add(student.email);
    }
    return true;
  };

  const handleCloseModal = () => {
    setFile(null);
    setStudents([]);
    handleClose();
  };

  const instructionsTooltip = (
    <Tooltip className="mytooltip" id="file-upload-tooltip">
      <ul style={{ paddingLeft: "20px", margin: 0, textAlign: "left" }}>
        <li>Password must be between 8 and 20 characters.</li>
        <li>Existing students in the class will be ignored.</li>
        <li>First Name, Email, and Password are required for each student.</li>
      </ul>
    </Tooltip>
  );

  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      centered
      size={"lg"}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Import Students</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFile">
            <Form.Label>Upload CSV or XLSX file</Form.Label>
            <div className="d-flex align-items-center gap-2 justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <Form.Control
                  className="w-75"
                  type="file"
                  onChange={handleFileChange}
                />
                <Button
                  variant="primary"
                  onClick={handleImport}
                  disabled={loading}
                  className="button-30 border-0 bg-dark"
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Import Sheet"
                  )}
                </Button>
              </div>
              <div className="d-flex align-items-center gap-2">
                <Button
                  variant="primary"
                  disabled={loading}
                  className="button-62 border-0 bg-dark d-flex align-items-center gap-2"
                  href="/uploads/students.xlsx" // Change this to the correct path
                  download
                >
                  <FontAwesomeIcon icon={faFileDownload} />
                  <span>Download Sample Sheet</span>
                </Button>
              </div>
            </div>
          </Form.Group>
        </Form>
        {gridData.length > 1 && (
          <>
            <div className="d-flex align-items-center gap-1 mt-4">
              <h5 className="mb-1">Instructions</h5>
              <OverlayTrigger placement="right" overlay={instructionsTooltip}>
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="p-0 ms-2"
                  style={{ cursor: "pointer" }}
                />
              </OverlayTrigger>
            </div>
            <div className="ag-theme-alpine mt-4" style={{ height: 300, width: "100%" }}>
              <AgGridReact
                ref={gridRef}
                rowData={gridData}
                columnDefs={columns}
                defaultColDef={{ flex: 1 }}
                animateRows={true}
                enableCellTextSelection={true}
                stopEditingWhenCellsLoseFocus={true}
                onCellValueChanged={(event) => {
                  // Ignore changes on the dummy row
                  if (event.data.isAddRow) {
                    return;
                  }
                  // Update state by finding the student via its unique id
                  const updatedStudents = students.map((student) =>
                    student.id === event.data.id ? event.data : student
                  );
                  console.log("updatedStudents", updatedStudents);
                  setStudents(updatedStudents);
                }}
              />
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button className="button-62" variant="secondary" onClick={handleCloseModal} disabled={loading}>
          Close
        </Button>
        <Button
          className="button-62"
          variant="primary"
          onClick={() => {
            if (validateData()) {
              handleCreateStudents();
            }
          }}
          disabled={loading || students.length === 0}
        >
          {loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            "Create Students"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportSheetModal;
