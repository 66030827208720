import React, { useState } from "react";
import { Col, Container, Row, Image, Button } from "react-bootstrap";
import PageHeading from "components/shared/PageHeading";
import deleteIcon from "../../../assets/delete.svg";
import Flag from "react-world-flags"; // Import the react-world-flags package
import CapitalizeFirst from "components/shared/CapitalizeFirst";
import { toast } from "react-toastify";
import { deleteWordFromStudySet } from "services/Teacher/Vocabulary.service"; // Assuming this API exists
import nodataimage from "../../../assets/nodataimage.svg";

const VocabularyListReports = ({
  studySetDetail,
  sourceLanguageTitle,
  targetLanguageTitle,
  studySetId=null,
  editable,
  enabledDelete=true,
  reload=()=>{},
}) => {
  const [loadingWord, setLoadingWord] = useState(null); // Track loading state for each word

  const getFlagCode = (languageTitle) => {
    switch (languageTitle) {
      case "English":
        return "GB"; // UK flag for English
      case "Swedish":
        return "SE"; // Swedish flag
      case "Italian":
        return "IT"; // Italian flag
      case "French":
        return "FR"; // French flag
      case "Spanish":
        return "ES"; // Spanish flag
      default:
        return "GB"; // Default to UK flag if the language is not recognized
    }
  };

  const handleDeleteWord = async (wordId) => {
    // Show loading toast
    const toastId = toast.loading("Deleting word...");

    try {
      setLoadingWord(wordId); // Set the word as being deleted

      // Delete the word from the study set
      await deleteWordFromStudySet(studySetId, wordId);

      // Show success toast
      toast.update(toastId, {
        render: "Word deleted successfully!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });

      // Reload study set details after deletion
      reload();
    } catch (error) {
      console.error("Error deleting word:", error);

      // Show error toast
      toast.update(toastId, {
        render: "Error deleting word.",
        type: "error",
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      setLoadingWord(null); // Reset the loading state after the operation
    }
  };

  return (
    <>
      <Container fluid>
        {
          studySetDetail?.length === 0 && (
            <div className='text-center bg-white pt-3 rounded-3 my-auto'>
            <Image src={nodataimage} alt="No Data" className='img-fluid' />
            <p>No words found</p>
        </div>
          )
        }
        {studySetDetail?.map((report, reportIndex) => (
          <div key={reportIndex} className="my-4 shadow p-2 rounded-3">
            <Row className="my-3">
              <Col xs={12}>
                <div className="border-0 report-listing px-3">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center gap-2">
                      <h4 className="mb-0">
                        <CapitalizeFirst text={report?.title} />
                      </h4>
                      <div className="d-flex align-items-center">
                        <Flag
                          code={getFlagCode(report?.sourceLanguage.title)}
                          style={{ width: 24, height: 24, marginRight: 8 }}
                        />
                        <span>{report?.sourceLanguage.title}</span>
                      </div>
                    </div>
                   {
                    enabledDelete && (
                      <div className="text-center" role="button">
                      <span
                        className="px-2 py-2 text-danger"
                        onClick={() => handleDeleteWord(report._id)} // Delete button logic
                      >
                        <Image
                          src={deleteIcon}
                          style={{
                            cursor: loadingWord === report._id ? "not-allowed" : "pointer", // Disable button during deletion
                            opacity: loadingWord === report._id ? 0.5 : 1, // Dim button while loading
                          }}
                        />
                      </span>
                    </div>
                    )
                   }
                  </div>

                  {/* Meaning */}
                  <ul className="nested-report-listing ps-4 mt-2 mb-0">
                    {report?.meaning.map((item, index) => (
                      <li
                        key={`${report?.id}-${index}`}
                        style={{
                          color: "#303972",
                          fontSize: "16px",
                          fontWeight: 400,
                          lineHeight: "24px",
                        }}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>

                  {/* Sentence */}
                  <div>
                    <p
                      className="mb-1"
                      style={{
                        color: "#303972",
                        fontSize: "18px",
                        fontWeight: 400,
                        lineHeight: "24px",
                      }}
                    >
                      <span
                        style={{
                          color: "#303972",
                          marginRight: "5px",
                          fontWeight: "bold",
                          fontSize: "18px",
                          lineHeight: "24px",
                        }}
                      >
                        Sentence:
                      </span>
                      <CapitalizeFirst text={report?.sentence} />
                    </p>
                  </div>

                  {/* Translations with Target Language Flag */}
                  <b
                    style={{
                      color: "#303972",
                      fontSize: "18px",
                      lineHeight: "24px",
                    }}
                  >
                    Translation:
                  </b>
                  {report?.translations?.map((translation, index) => (
                    <div
                      key={`${report?.id}-${index}`}
                      className="d-flex align-items-center gap-2"
                    >
                      <Flag
                        code={getFlagCode(report.translations[0]?.language?.title || targetLanguageTitle)}
                        style={{ width: 24, height: 24, marginLeft: 2 }}
                      />
                      <CapitalizeFirst text={translation.text} />
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </Container>
    </>
  );
};

export default VocabularyListReports;
