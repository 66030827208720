import Select from 'components/shared/Select';
import React, { useState } from 'react';
import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

const AssignmentFilter = ({ show, onClose, applyFilters }) => {
    const [subject, setSubject] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [status, setStatus] = useState(null);

    const subjects = [
        { value: 'English', label: 'English' },
        { value: 'Swedish', label: 'Swedish' },
        { value: 'French', label: 'French' },
        { value: 'Italian', label: 'Italian' },
        { value: 'Spanish', label: 'Spanish' },
    ];

    const statusOption = [
        { value: 'pending', label: 'Pending' },
        { value: 'in-progress', label: 'In-Progress' },
        { value: 'revise-needed', label: 'Revise' },
        { value: 'submitted', label: 'Submitted' },
        { value: 'terminated', label: 'Terminated' },
        { value: 'marked', label: 'Marked' },
    ];

    const applyFilterSettings = () => {
        const filters = {};

        if (subject) filters.subject = subject;
        if (fromDate) filters.fromDate = fromDate.toISOString().split('T')[0];
        if (toDate) filters.toDate = toDate.toISOString().split('T')[0];
        if (status) filters.status = status;

        applyFilters(filters);
        onClose();
    };

    const clearFilters = () => {
        setSubject('');
        setFromDate(null);
        setToDate(null);
        setStatus(null);

        applyFilters({});
        onClose();
    };

    return (
        <Offcanvas style={{ width: "350px" }} placement={"end"} show={show} onHide={onClose}>
            <Offcanvas.Header closeButton style={{ borderBottom: "2px solid #ea4f50" }}>
                <Offcanvas.Title style={{ color: "#ea4f50" }}>Filters</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="d-flex flex-column">
                <div className="flex-grow-1">
                    <Select
                        heading={"Select Subject"}
                        value={subject}
                        onChange={setSubject}
                        options={subjects}
                        ariaLabel="Select an option"
                    />
                    {/* <hr className='text-muted' /> */}
                    {/* <Row>
                        <Form.Label>Select Expiry Date Range</Form.Label>
                        <Col xs={6}>
                            <label htmlFor="from-date" style={{ color: "#9e9e9e" }}>From </label>
                            <DatePicker
                                id="from-date"
                                selected={fromDate}
                                onChange={(date) => setFromDate(date)}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                className="form-control"
                                placeholderText="Select from date"
                            />
                        </Col>
                        <Col xs={6}>
                            <label htmlFor="to-date" style={{ color: "#9e9e9e" }}>To </label>
                            <DatePicker
                                id="to-date"
                                selected={toDate}
                                onChange={(date) => setToDate(date)}
                                dateFormat="dd/MM/yyyy"
                                isClearable
                                className="form-control"
                                placeholderText="Select to date"
                            />
                        </Col>
                    </Row> */}
                    <hr className='text-muted' />
                    <Select
                        heading={"Status"}
                        value={status}
                        onChange={setStatus}
                        options={statusOption}
                        ariaLabel="Select an option"
                    />
                </div>
                <div className="d-flex justify-content-between mt-3">
                <Button
                    variant="secondary"
                    type='submit'
                    className='mt-1 px-4 button-style py-2 border-0'
                    style={{ background: "linear-gradient(270deg, #272727 0%, #4B4B4B 100%)" }}
                    onClick={clearFilters}
                >
                    Clear Filters
                </Button>
                <Button
                    variant="primary"
                    type='submit'
                    className='mt-1 px-4 button-style py-2 border-0'
                    onClick={applyFilterSettings}
                >
                     Apply Filters
                </Button>
                    {/* <Button variant="secondary" onClick={clearFilters}>
                        Clear Filters
                    </Button>
                    <Button variant="primary" style={{background:"#303972",borderColor:"#303972"}} onClick={applyFilterSettings}>
                        Apply Filters
                    </Button> */}
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
}

export default AssignmentFilter;
