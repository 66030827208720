import RevisionModal from "components/modals/RevisionModal";
import PageHeading from "components/shared/PageHeading";
import React, { useEffect, useState } from "react";
import { Col, Row, Form, Button, Placeholder, Alert } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
  getSubmission,
  markSubmission,
  sendToRevision,
} from "services/Student/Submission.services";

const MarkSubmission = () => {
  const [exerciseInfo, setExerciseInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submittedAnswers, setSubmittedAnswers] = useState({});
  const [marks, setMarks] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [totalScore, setTotalScore] = useState(0);
  const [maxMarks, setMaxMarks] = useState(0);
  const [isMarked, setIsMarked] = useState(false);
  const [status, setStatus] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [audioUrl, setAudioUrl] = useState("");

  const navigate = useNavigate();
  const params = useParams();
  const id = params._id;

  useEffect(() => {
    const fetchSubmissionDetails = async () => {
      try {
        setLoading(true);
        const response = await getSubmission(id);
        const { exercise, answers, status, totalScore } =
          response?.data?.result;

        setIsMarked(status === "marked");
        setStatus(status);
        setTotalScore(totalScore);
        setAudioUrl(exercise?.audioUrl?.url);


        const totalPossibleMarks = exercise.questions.reduce(
          (sum, question) => sum + question.score,
          0
        );

        setMaxMarks(totalPossibleMarks);

        const answersMap = {};
        const initialMarks = [];
        answers.forEach((ans) => {
          answersMap[ans.question] = ans.answer;
          if (
            ["written-answer", "essay", "fill-in-the-blank"].includes(
              exercise.questions.find((q) => q._id === ans.question).type
            )
          ) {
            initialMarks.push({
              questionId: ans.question,
              score: ans.score || 0,
              feedback: ans.feedback || "",
            });
          }
        });

        setSubmittedAnswers(answersMap);
        setMarks(initialMarks);
        setExerciseInfo(exercise);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching submission details:", error);
        setErrorMessage("Failed to fetch submission details.");
        setLoading(false);
      }
    };

    fetchSubmissionDetails();
  }, [id]);

  const isCorrect = (studentAnswer, correctAnswer) => {
    return studentAnswer?.toLowerCase() === correctAnswer?.toLowerCase();
  };

  const handleMarkChange = (questionId, score, feedback) => {
    setMarks((prevMarks) =>
      prevMarks.map((mark) =>
        mark.questionId === questionId ? { ...mark, score, feedback } : mark
      )
    );
  };

  const isWritingExercise = exerciseInfo?.exerciseType === "writing";
  const isReadingExercise = exerciseInfo?.exerciseType === "reading";
  const isListeningExercise = exerciseInfo?.exerciseType === "listening";

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModal(false);
  };


  useEffect(() => {
    const writtenQuestions = exerciseInfo?.questions.filter((q) =>
      ["written-answer", "essay", "fill-in-the-blank"].includes(q.type)
    );
    const allWrittenQuestionsMarked = writtenQuestions?.every((question) => {
      const mark = marks.find((mark) => mark.questionId === question._id);
      return (
        mark && mark.score !== null && mark.score !== "" && mark.score >= 0
      );
    });

    setIsFormValid(allWrittenQuestionsMarked);
  }, [marks, exerciseInfo]);

  const handleScoreChange = (questionId, score, maxScore) => {
    if (score > maxScore) {
      setErrorMessage(`Score for this question cannot exceed ${maxScore}`);
      return;
    }

    setMarks((prevMarks) =>
      prevMarks.map((mark) =>
        mark.questionId === questionId
          ? { ...mark, score: score, feedback: mark.feedback }
          : mark
      )
    );
    setErrorMessage(null);
  };

  const handleSubmitMarks = async () => {
    if (!isFormValid) {
      setErrorMessage("Please mark all questions before submitting.");
      return;
    }

    try {
      const payload = {
        submissionId: id,
        marks,
      };

      await markSubmission(payload);
      navigate("/assignments");
    } catch (error) {
      console.error("Error submitting marks:", error);
      setErrorMessage("Error submitting marks. Please try again.");
    }
  };

  const handleRevision = async () => {
    try {
      setLoading(true);
      const payload = {
        submissionId: id,
        feedback,
      };

      await sendToRevision(payload);
      handleHideModal();
      setLoading(false);
      navigate("/assignments");
    } catch (error) {
      setLoading(false);
      console.error("Error submitting marks:", error);
      setErrorMessage("Error submitting marks. Please try again.");
    }
  };

  return (
    <>
      <div>
        {exerciseInfo ? (
          <div className="d-flex justify-content-between">
            <div>
              <PageHeading
                heading={exerciseInfo?.exerciseType}
                text={`Title: ${exerciseInfo?.title}`}
              />
            </div>
            <div>
              {isMarked ? (
                <h5 className="pt-2 mb-0 me-2"> Total Score: {totalScore} / {maxMarks}</h5>
              ) : (
                <h5 className="pt-2 mb-0 me-2 text-capitalize">Status: {status}</h5>

              )}
              {/* {!isMarked &&
                <h5 className="pt-2 mb-0 me-2 text-capitalize">Status: {status}</h5>
              }
              {isMarked &&
                <h5 className="pt-2 mb-0 me-2"> Total Score: {totalScore} / {maxMarks}</h5>
              } */}
              {/* : (
                )} */}
            </div>

          </div>
        ) : (
          <Placeholder className="mb-3" as="div" animation="glow">
            <Placeholder xs={3} className="mb-2" />
            <div>
              <Placeholder xs={6} />
            </div>
          </Placeholder>
        )}
      </div>

      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}



      <div
        style={{
          height: "65vh",
          border: "1px solid #A7A7A7",
          borderRadius: "30px",
          padding: "30px",
          boxShadow: "rgba(234, 79, 80, 0.1) 0px 4px 12px",
        }}
      >
        <Row className="h-100">
          {isReadingExercise && (<Col
            xs={6}
            style={{
              borderRight: "2px solid #8A8A8A",
              height: "52vh",
            }}
          >
            {exerciseInfo ? (
              <>
                <h3 className="text-capitalize">Comprehension Passage</h3>
                <div
                  className="custom-scrollbar"
                  style={{
                    height: "100%",
                    overflow: "auto",
                  }}
                >
                  <p>{exerciseInfo?.passage}</p>
                </div>
              </>
            ) : (
              <Placeholder as="div" animation="glow">
                <Placeholder xs={6} className="mb-2" />
                <Placeholder xs={8} />
                <Placeholder xs={4} />
                <Placeholder xs={7} />
              </Placeholder>
            )}
          </Col>)}
          {isListeningExercise && (
            <Col
              xs={6}
              className="custom-scrollbar"
              style={{
                height: "60vh",
                overflow: "auto",
                borderRight: "2px solid #8A8A8A",
              }}
            >
              <div className="d-flex align-items-center justify-content-between mb-3">
                {/* <h4>{exerciseInfo.title}</h4>
                  <span className={`bg-primary px-3 mx-2 rounded-4 text-white`}>
                    {exerciseInfo.status}
                  </span> */}
                <h5>
                  Listening Exercise Audio:
                </h5>
              </div>
              <div className="">
                <audio controls src={audioUrl} className="mt-5 w-100" />
              </div>
            </Col>
          )}
          <Col xs={6} lg={isWritingExercise ? 12 : 6} style={{ height: "60vh", overflow: "auto" }} className="custom-scrollbar" >
            {exerciseInfo?.questions.map((question, index) => {
              const studentAnswer = submittedAnswers[question._id];
              const correctAnswer = question.correctAnswer;

              const backgroundColor =
                question.type !== "written-answer" && question.type !== "fill-in-the-blank"
                  ? isCorrect(studentAnswer, correctAnswer)
                    ? "#d4edda" // Green for correct
                    : "#f8d7da"
                  : "transparent"; // Red for incorrect

              return (
                <div
                  key={question._id}
                  style={{
                    marginBottom: "20px",
                    backgroundColor: backgroundColor,
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <h3 className="text-capitalize mb-3">
                    {`Question ${index + 1} of ${exerciseInfo?.questions.length
                      }`}
                  </h3>

                  <h5 style={{ fontWeight: "700" }} className="text-capitalize">
                    {question.type.replace("-", " ")}
                  </h5>

                  <h6>{`Question: ${question.questionText}`}</h6>

                  {/* Render for multiple-choice, true-false, and fill-in-the-blank */}
                  {[
                    "multiple-choice",
                    "true-false",
                    // "fill-in-the-blank",
                  ].includes(question.type) && (
                      <>
                        <div>
                          <strong>Student's Answer:</strong>{" "}
                          {studentAnswer || "N/A"}
                        </div>
                        <div>
                          <strong>Correct Answer:</strong> {correctAnswer}
                        </div>
                        <div>
                          <strong>Auto-marked Score:</strong>{" "}
                          {isCorrect(studentAnswer, correctAnswer)
                            ? question.score
                            : 0}
                        </div>
                      </>
                    )}

                  {/* Render for written-answer and essay */}
                  {["written-answer", "essay", "fill-in-the-blank"].includes(question.type) && (
                    <>
                      <Form.Group>
                        <Form.Label>Student's Answer:</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          value={studentAnswer || ""}
                          readOnly
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Score (max {question.score}):</Form.Label>
                        <Form.Control
                          type="number"
                          min="0"
                          max={question.score}
                          // value={
                          //   marks.find(
                          //     (mark) => mark.questionId === question._id
                          //   )??.score || ""
                          // }
                          value={
                            marks.find((mark) => mark.questionId === question._id)?.score ?? ""
                          }
                          onChange={(e) =>
                            handleScoreChange(
                              question._id,
                              parseInt(e.target.value, 10),
                              question.score
                            )
                          }
                          disabled={isMarked} // Disable if already marked
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Feedback:</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={2}
                          value={
                            marks.find(
                              (mark) => mark.questionId === question._id
                            )?.feedback || ""
                          }
                          onChange={(e) => {
                            setMarks((prevMarks) =>
                              prevMarks.map((mark) =>
                                mark.questionId === question._id
                                  ? { ...mark, feedback: e.target.value }
                                  : mark
                              )
                            );
                          }}
                          disabled={isMarked} // Disable if already marked
                        />
                      </Form.Group>
                    </>
                  )}
                </div>
              );
            })}
          </Col>
        </Row>
      </div>

      <div className="mt-2 d-flex justify-content-end gap-2">
        {!isMarked && !loading && (
          <>
            <Button
              variant="primary"
              onClick={handleShowModal}
              className="mt-1 px-4 button-style py-2 border-0"
              disabled={false}
              style={{
                background: "#303972"
              }}
            >
              Send Back To Revise
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmitMarks}
              className="mt-1 px-4 button-style py-2 border-0"
              disabled={!isFormValid}
            >
              Submit Marks
            </Button>
          </>
        )}
      </div>
      <RevisionModal show={showModal} handleClose={handleHideModal} handleConfirm={handleRevision} loading={loading} />
    </>
  );
};

export default MarkSubmission;
