import React, { createContext, useContext, useState, useEffect } from "react";
import io from "socket.io-client";
import { useAuth } from "./AuthContext";

const SocketContext = createContext();

// Replace with your server URL
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL; // Adjust accordingly

export const SocketProvider = ({ children }) => {
  const { user } = useAuth();  // Access the user from the AuthContext
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    if (!user?.token) {
      console.log('No token available for socket connection');
      return; // Don't try to connect if there's no token
    }

    // Create the socket connection when the component mounts
    const newSocket = io(SOCKET_URL, {
      query: { token: user?.token }, // Correctly reference the token
      forceNew: true,
    });


    newSocket.on("connect", () => {
      console.log("Socket connected:", newSocket.id);  // Should show socket ID
    });

    newSocket.on("disconnect", () => {
      console.log("Socket disconnected");
    });

    newSocket.on("error", (err) => {
      console.log("Socket error:", err);
    });

    setSocket(newSocket);

    // Clean up the socket connection when the component unmounts
    return () => {
      newSocket.close();
      console.log("Socket connection closed");
    };
  }, [user]); // Re-run when the token changes

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

// Custom hook to use the socket
export const useSocket = () => {
  const socket = useContext(SocketContext);
  if (!socket) {
    throw new Error("useSocket must be used within a SocketProvider");
  }
  return socket;
};
