import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Placeholder, Row } from "react-bootstrap";
import InputComponent from "components/shared/InputComponent";
import SelectReact from "react-select";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import {
  getAssignmentDetails,
  updateAssignment,
} from "services/Teacher/Assignments.service";
import { getAllTeachersAndStudents } from "services/Teacher/User.service";
import { getClassTeachersAndStudents } from "services/Teacher/ClassesAndGroups.service";

const UpdateAssignmentModal = ({ show, onHide, assignmentId, UpdateAssignment }) => {
  const [formState, setFormState] = useState({
    title: "",
    startDate: new Date(),
    endDate: new Date(),
    maxTime: null,
    safeBrowser: false,
    forceSubmission: false,
    selectedStudents: [],
  });
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const schoolId = JSON.parse(localStorage.getItem("user"))?.school;
  const [maxTimeEnabled, setMaxTimeEnabled] = useState(false);

  useEffect(() => {
    if (show && assignmentId) {
      fetchAssignmentDetails();
    }
  }, [show, assignmentId]);

  const fetchAssignmentDetails = async () => {
    try {
      setLoading(true);
      const response = await getAssignmentDetails(assignmentId);
      const assignment = response.data.results;

      const selectedStudentsFromApi = assignment.selectedStudents.map(
        (student) => ({
          value: student._id,
          label: student.fullName,
        })
      );

      setFormState({
        title: assignment.title,
        startDate: new Date(assignment.startDate),
        endDate: new Date(assignment.endDate),
        maxTime: assignment.maxTime,
        safeBrowser: assignment.safeBrowser,
        forceSubmission: assignment.forceSubmission,
        selectedStudents: selectedStudentsFromApi,
      });

      // Set maxTimeEnabled based on whether maxTime is null
      setMaxTimeEnabled(assignment.maxTime !== null);

      await fetchStudentsForEditing(
        assignment.sourceId ? assignment.sourceId._id : null,
        assignment.sourceType,
        selectedStudentsFromApi
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching assignment details:", error);
      toast.error("Failed to fetch assignment details.");
    }
  };

  const fetchStudentsForEditing = async (
    sourceId,
    sourceType,
    currentSelectedStudents
  ) => {
    try {
      setLoading(true);

      let fetchedStudents = [];
      if (sourceType === "individual" || !sourceId) {
        // Fetch all students in the school for individual assignments
        const response = await getAllTeachersAndStudents(schoolId, "student");
        fetchedStudents = response?.data.users || [];
      } else {
        // Fetch students based on the class or group sourceId
        const response = await getClassTeachersAndStudents(sourceId, "student", 1, 1000);
        fetchedStudents = response?.data.results.members || [];
      }

      const studentOptions = fetchedStudents.map((student) => ({
        value: student._id,
        label: student.fullName,
      }));

      setStudents(studentOptions); // Set the dropdown options for students

      // Filter out selected students no longer available
      setFormState((prevState) => ({
        ...prevState,
        selectedStudents: currentSelectedStudents.filter((student) =>
          studentOptions.some((option) => option.value === student.value)
        ),
      }));

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching students for editing:", error);
      toast.error("Failed to fetch students for editing.");
    }
  };

  const handleInputChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleStudentSelection = (selected) => {
    setFormState((prevState) => ({
      ...prevState,
      selectedStudents: selected,
    }));
  };

  const validateForm = () => {
    if (!formState.title) return "Title is required.";
    if (!formState.startDate) return "Start date is required.";
    if (!formState.endDate) return "End date is required.";
    if (formState.selectedStudents.length === 0)
      return "At least one student must be selected.";
    return null;
  };

  const handleSubmit = async () => {
    const error = validateForm();
    if (error) {
      toast.error(error);
      return;
    }

    setIsSubmitting(true);

    const payload = {
      title: formState.title,
      startDate: formState.startDate,
      endDate: formState.endDate,
      maxTime: maxTimeEnabled ? formState.maxTime : null, // Send maxTime as null if toggle is off
      safeBrowser: formState.safeBrowser,
      forceSubmission: formState.forceSubmission,
      selectedStudents: formState.selectedStudents.map(
        (student) => student.value
      ),
    };

    try {
      await updateAssignment(assignmentId, payload);
      toast.success("Assignment updated successfully!");
      // onHide();
      UpdateAssignment();
    } catch (error) {
      console.error("Error updating assignment:", error);
      toast.error("Failed to update assignment.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleModalClose = () => {
    setFormState({
      title: "",
      startDate: new Date(),
      endDate: new Date(),
      maxTime: null,
      forceSubmission: false,
      selectedStudents: [],
    });
    setStudents([]);
    setMaxTimeEnabled(false); // Reset time limit toggle
    onHide();
  };
  const handleSafeBrowserToggle = () => {
    setFormState((prev) => ({
      ...prev,
      safeBrowser: !prev.safeBrowser,
    }));
  };
  return (
    <Modal
      show={show}
      onHide={handleModalClose}
      centered
      size="lg"
      style={{ minHeight: "80vh" }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Assignment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div>
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>{" "}
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>{" "}
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
            <Placeholder as="p" animation="glow">
              <Placeholder xs={8} />
            </Placeholder>{" "}
            <Placeholder as="p" animation="glow">
              <Placeholder xs={10} />
            </Placeholder>{" "}
            <Placeholder as="p" animation="glow">
              <Placeholder xs={12} />
            </Placeholder>
          </div>
        ) : (
          <div>
            <InputComponent
              label="Assignment Title"
              placeholder="Enter assignment title"
              value={formState.title}
              setValue={(value) => handleInputChange("title", value)}
            />

            <Row>
              <Col xs={6}>
                <Form.Label>Start Date</Form.Label>
                <DatePicker
                  selected={formState.startDate}
                  onChange={(date) => handleInputChange("startDate", date)}
                  className="form-control"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </Col>
              <Col xs={6}>
                <Form.Label>End Date</Form.Label>
                <DatePicker
                  selected={formState.endDate}
                  onChange={(date) => handleInputChange("endDate", date)}
                  className="form-control"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col xs={6}>
                <Form.Group>
                  <Form.Label>Time Limit</Form.Label>
                  <Form.Check
                    type="switch"
                    label="Enable Time Limit"
                    checked={maxTimeEnabled}
                    onChange={(e) => setMaxTimeEnabled(e.target.checked)}
                  />
                  <InputComponent
                    className="w-50 pe-2 mt-3"
                    type="number"
                    placeholder="Enter time limit in minutes"
                    value={formState.maxTime}
                    setValue={(value) => handleInputChange("maxTime", value)}
                    disabled={!maxTimeEnabled}
                  />
                </Form.Group>
              </Col>
              <Col xs={6}>
                <Form.Label>Safe Browser</Form.Label>
                <Form.Check
                  type="switch"
                  label="Enable Safe Browser"
                  checked={formState.safeBrowser}
                  onChange={handleSafeBrowserToggle}
                />
              </Col>
            </Row>

            {/* <Form.Group className="mt-3">
              <Form.Check
                type="switch"
                label="Force Submission"
                checked={formState.forceSubmission}
                onChange={(e) =>
                  handleInputChange("forceSubmission", e.target.checked)
                }
              />
            </Form.Group> */}

            <Form.Label className="mt-3">Selected Students</Form.Label>
            <SelectReact
              isMulti
              placeholder="Select Students"
              className="mb-3 exercise-select"
              isDisabled={loading}
              options={students} // Dynamically updated options
              value={formState.selectedStudents} // Preserve selected students
              onChange={(selected) =>
                setFormState((prevState) => ({
                  ...prevState,
                  selectedStudents: selected,
                }))
              }
            />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="button-style border-0 px-3"
          style={{
            background: "linear-gradient(270deg, #272727 0%, #4B4B4B 100%)",
          }}
          onClick={handleModalClose}
        >
          Cancel
        </Button>
        <Button
          className="button-62 px-3"
          variant="primary"
          onClick={handleSubmit}
          disabled={isSubmitting || loading}
        >
          Update
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdateAssignmentModal;
