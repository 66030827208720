import { Line } from 'rc-progress';
import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';

const data = [
    { name: 'Assign 1', pv: 20 },
    { name: 'Assign 2', pv: 30 },
    { name: 'Assign 3', pv: 5 },
    { name: 'Assign 4', pv: 10 },
    { name: 'Assign 5', pv: 20 },
    { name: 'Assign 6', pv: 20 },
    { name: 'Assign 7', pv: 20 },
    { name: 'Assign 4', pv: 60 },
    { name: 'Assign 5', pv: 20 },
    { name: 'Assign 6', pv: 40 },
    { name: 'Assign 7', pv: 35 },
];

const maxValue = Math.max(...data.map(item => item.pv));


const CustomTooltip = ({ active, payload, maxValue }) => {
    if (active && payload && payload.length) {
        const percentage = (payload[0].value / maxValue) * 100;
        return (
            <div className="custom-tooltip text-center" style={{ backgroundColor: '#f7ecec', padding: '10px', borderRadius: '5px' }}>
                <p className="label mb-0" style={{ fontWeight: 'bold', color: "#858585" }}>Average score</p>
                <p className="intro mb-0" style={{ color: "#AE3E3E", fontWeight: 'bold' }}>
                    {`${payload[0].value}/${maxValue}`}
                </p>
                <div>
                    <Line percent={percentage} strokeWidth={8} strokeColor="#AE3E3E" trailColor="#ffffff" />
                </div>
            </div>
        );
    }

    return null;
};


export default class Statistics extends PureComponent {
    render() {
        return (
            <ResponsiveContainer className={"my-4"} width="100%" height={400}>
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                    <defs>
                        <linearGradient id="gradient" x1="0" y1="0" x2="1" y2="0">
                            <stop offset="0%" stopColor="#EC7677" />
                            <stop offset="100%" stopColor="#DE494B" />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="3 1" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip content={<CustomTooltip maxValue={maxValue} />} />
                    <Bar
                        dataKey="pv"
                        fill="url(#gradient)"
                        barSize={20}
                        radius={[10, 10, 0, 0]}
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={`url(#gradient)`} />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        );
    }
}
