import React from 'react'
import { Card, Col, Image, Row } from 'react-bootstrap'
import { CircularProgressbar } from 'react-circular-progressbar'
import bgprogress from "../../assets/progressborder.svg"
const ProgressBarCards = ({ value, heading, count }) => {
    return (
        <>
            <Card className='my-3 px-3 py-4 pb-3 border-0 school-cards text-decoration-none'
                style={{
                    borderRadius: "10px",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 14px 0px",
                    minHeight:"140px"
                }}>
                    
                <Row>
                    <Col xs={4} lg={4} className='mx-auto'>
                        <div className='position-relative schooldetailcards text-center text-lg-left' style={{width:"100px",height:"100px"}}>
                            <Image src={bgprogress} alt="Background" className="position-absolute bg-progress"
                                style={{
                                    right: "-22px",
                                    top: "56%",
                                    transform: "translateY(-50%)",
                                    width: "100%",
                                    zIndex: 1,
                                }} />
                            <CircularProgressbar value={value} text={`${value?value?.toFixed():0}%`} styles={{ width: "100%", height: "100%", zIndex: 2 }} />
                        </div>
                    </Col>
                    <Col xs={8} lg={8} className='d-flex justify-content-center align-items-center'>
                        <div className='mt-lg-1 mt-0 text-center text-lg-left'>
                            <h4 style={{ color: "#5e5e5e", fontWeight: "700", fontSize: "16px" }}>{heading}</h4>
                            <h2 style={{ color: "#5e5e5e", fontWeight: "700", fontSize: "40px" }}>{count}</h2>
                        </div>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default ProgressBarCards