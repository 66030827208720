import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PageHeading from 'components/shared/PageHeading';
import PaginationButtons from 'components/shared/PaginationButtons';
import { Placeholder } from 'react-bootstrap'; // Import Placeholder from react-bootstrap

const LatestActivities = ({ limit, heading, timeStamps, currentPage, setCurrentPage, totalPages, loadingTimeStamp }) => {
    const [loading, setLoading] = useState(loadingTimeStamp);
    useEffect(() => {
        setLoading(loadingTimeStamp);
    }, [loadingTimeStamp]);
    return (
        <>
            <PageHeading heading={heading ? heading : "Latest Activities"} />

            <ul className="activity-list mx-2 mt-1">
                {
                    loading ? (
                        // Skeleton loader while loading
                        // Array.from({ length: limit }).map((_, index) => (
                            <li className="activity-item">
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={4} />
                                </Placeholder>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={4} />
                                </Placeholder>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={4} />
                                </Placeholder>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={4} />
                                </Placeholder>
                                <Placeholder as="p" animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                            </li>
                        // ))
                    ) : timeStamps?.length === 0 ? (
                        <p className='my-3 mx-3'>
                            No Activity in the class.
                        </p>
                    ) : (
                        timeStamps?.map((activity, index) => {
                            const formattedDate = moment(activity.createdAt).format('dddd, MMMM D YYYY');
                            return (
                                <li key={index} className="activity-item">
                                    <div className="activity-circle"></div>
                                    <div className="activity-content">
                                        <p className='mb-0' style={{ fontSize: "16px", lineHeight: "30px", fontWeight: 400, color: "#A098AE" }}>
                                            {formattedDate}
                                        </p>
                                        <p style={{ fontSize: "18px", lineHeight: "30px", fontWeight: 400, color: "#303972" }}>
                                            <span dangerouslySetInnerHTML={{ __html: activity.note }} />
                                        </p>
                                    </div>
                                </li>
                            );
                        })
                    )
                }
            </ul>

            <PaginationButtons
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={totalPages}
                position={"left"}
            />
        </>
    );
};

export default LatestActivities;
