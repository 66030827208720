import React from 'react';
import { Button } from 'react-bootstrap';

const PaginationButtons = ({ currentPage, setCurrentPage, totalPages, position }) => {
    // Show More button functionality
    const handleShowMore = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    // Show Less button functionality
    const handleShowLess = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Don't show the buttons if there's only one page
    if (totalPages <= 1) return null;

    return (
        // <div className="pagination-buttons d-flex justify-content-between mt-3">
        <div className={`pagination-buttons d-flex justify-content-${position} mt-3 gap-2`}>
            {currentPage > 1 && (
                // <button onClick={handleShowLess} className="btn btn-outline-primary">
                //     Show Less
                // </button>
                <Button
                    className="button-style border-0 px-3 d-flex align-items-center justify-content-center gap-1"
                    variant="success"
                    onClick={handleShowLess}
                >Previous</Button>
            )}
            {currentPage < totalPages && (
                <Button onClick={handleShowMore} className="button-style border-0 px-4 d-flex align-items-center justify-content-center gap-1" variant="success">
                    Next
                </Button>
            )}
        </div>
    );
};

export default PaginationButtons;
