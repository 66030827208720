import React, { useState } from "react";
import { Button, Image, Spinner, Table } from "react-bootstrap";
import PageHeading from "components/shared/PageHeading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookAtlas } from "@fortawesome/free-solid-svg-icons";
import response from "../../../assets/respondant.jpg"; // example image, adjust accordingly
import CapitalizeFirst from "components/shared/CapitalizeFirst";
import { createSubmission, generateSebFile } from "services/Student/Submission.services";
import nodataimage from "../../../assets/nodataimage.svg";
import { useNavigate } from "react-router-dom";
import DynamicModal from "components/modals/DynamicModal";
import SebInstructionsModal from "components/modals/SebInstructionsModal";
import { toast } from "react-toastify";

const UpcomingDeadlines = ({ data, heading }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const [currentSubId, setCurrentSubId] = useState(null)
  const [loading, setLoading] = useState(false); // New state for loading
  const [showSEBModal, setShowSEBModal] = useState(false);
  const [loadingSeb, setLoadingSeb] = useState(false);

  const getLevelBackgroundColor = (level) => {
    switch (level) {
      case "pending":
        return "#aaaaaa";
      case "in-progress":
        return "#4DA9F4";
      case "submitted":
        return "#0FE133";
      case "marked":
        return "#EB3232";
      default:
        return "#B0BEC5";
    }
  };

  const getButtonLabel = (status) => {
    switch (status) {
      case "pending":
      case "in-progress":
        return "Attempt";
      case "submitted":
        return "View Submission";
      case "marked":
        return "View Remarks";
      case "revise-needed":
        return "Re-Attempt";
      default:
        return "Take Action";
    }
  };

  const handleAction = (status, id, subId, safeBrowser, studySet) => {
    // switch (status) {
    //   case "pending":
    //     handlePendingAction(id);
    //     break;
    //   case "in-progress":
    //     handleInProgressAction(subId);
    //     break;
    //   case "revise-needed":
    //     handleReviseAction(id, subId);
    //     break;
    //   default:
    //     console.log("Unknown action");
    // }
    if (studySet) {
      navigate(`/vocabulary/assigned/${studySet._id}?assignment=${id}`);
    }
    setCurrentStatus(status);
    setCurrentId(id);
    setCurrentSubId(subId);
    if (["pending", "in-progress", "revise-needed"].includes(status)) {
      // setShowModal(true);
      if (safeBrowser) {
        // handleDownloadSebFile(id);
        handleConfirm(safeBrowser);
      } else {
        setShowModal(true);
      }
    }
  };
  const handleConfirm = (safeBrowser) => {
    switch (currentStatus) {
      case "pending":
        handlePendingAction(currentId, safeBrowser);
        break;
      case "in-progress":
        handleInProgressAction(currentSubId, safeBrowser);
        break;
      case "revise-needed":
        handleReviseAction(currentId, currentSubId, safeBrowser);
        break;
      default:
        console.log("Unknown action");
    }

    setShowModal(false);
  };
  const handlePendingAction = async (id, safeBrowser) => {
    console.log(`Handling pending action for assignment ${id}`);
    if (safeBrowser) {
      // handleDownloadSebFile(id)
      setShowSEBModal(true);
    } else {
      setLoading(true);
      try {
        const response = await createSubmission({
          assignmentId: id,
        });
        console.log("response submission", response.data);
        navigate(`/quiz/attempt/${response.data?.result?._id}`);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }
  };

  const handleInProgressAction = async (id, safeBrowser) => {
    console.log(`Handling in-progress action for assignment ${id}`);
    if (safeBrowser) {
      setShowSEBModal(true);
    } else {
      navigate(`/quiz/attempt/${id}`);
    }
  };

  const handleReviseAction = async (id, subId, safeBrowser) => {
    console.log(`Handling revise action for submission ${id}`);
    if (safeBrowser) {
      // handleDownloadSebFile(subId)
      setShowSEBModal(true);
    } else {
      setLoading(true);
      await createSubmission(
        {
          assignmentId: id,
          submissionId: subId,
        },
        "revise-needed"
      );
      setLoading(false);
      navigate(`/quiz/attempt/${subId}`);
    }
  };
  const handleDownloadSebFile = async () => {
    setLoadingSeb(true);
    try {
      const response = await generateSebFile(currentId);

      if (response && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `quiz_${currentId}.seb`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        setShowSEBModal(false);
        setLoadingSeb(false);
        // alert("Safe Exam Browser quiz file has been downloaded.");
      } else {
        // throw new Error("Invalid response data");
        toast.error("Error While Downloading")
        setLoadingSeb(false);
      }
    } catch (error) {
      toast.error("Error While Downloading")
      console.error("Failed to download SEB file:", error);
      setLoadingSeb(false);
    }
  };
  return (
    <>
      <div className="my-2">
        <PageHeading heading={heading ? heading : "Upcoming Deadlines"} />
        <div className="my-3 custom-scrollbar">
          <Table hover striped borderless responsive={true}>
            <thead className="bg-secondary">
              <tr >
                <th style={{ color: "#303972" }}></th>
                <th style={{ color: "#303972" }}>Assignment Name</th>
                <th style={{ color: "#303972" }}>Deadline</th>
                <th style={{ color: "#303972" }}>Status</th>
                <th style={{ color: "#303972" }}>Total Points</th>
                <th style={{ color: "#303972" }}>Actions</th>
              </tr>
            </thead>
            <tbody className="my-auto">
              {
                data.length === 0 && (
                  <tr className="w-100">
                    <td colSpan="10" className="text-center py-3">
                      <Image src={nodataimage} />
                      <p className="pt-2 text-secondary fw-bold fs-5">No upcoming assignments. You are all set for now!</p>
                    </td>
                  </tr>
                )
              }
              {data.map((assignment) => (
                <tr key={assignment._id}>
                  <td valign="middle">
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        height: "60px",
                        width: "60px",
                        background: "#e15455",
                        borderRadius: "20px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faBookAtlas}
                        style={{
                          color: "white",
                          width: "30px",
                          height: "30px",
                        }}
                      />
                    </div>
                  </td>
                  <td valign="middle" className="pt-3">
                    <div>
                      <h6>{assignment.title}</h6>
                      <span className="text-muted">
                        {assignment.exercise ? (
                          <>
                            {assignment.exercise.subject.title} (
                            <CapitalizeFirst
                              text={assignment.exercise.exerciseType}
                            />
                            )
                          </>
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </td>
                  <td valign="middle" className="pt-3">
                    {assignment.endDate? new Date(assignment.endDate).toLocaleDateString(): "No Deadline"}
                  </td>
                  <td valign="middle" className="py-3" style={{ color: "#A098AE" }}>
                    <span
                      style={{
                        display: "inline-block",
                        padding: "3px 10px",
                        borderRadius: "30px",
                        backgroundColor: getLevelBackgroundColor(
                          assignment?.submissionStatus
                        ),
                        color: "#fff",
                        fontSize: "14px",
                      }}
                    >
                      {assignment.type === "studySet"? "ASSIGNED" :assignment?.submissionStatus.toUpperCase() ||
                        "Not Submitted"}
                    </span>
                  </td>
                  <td valign="middle" className="pt-3">
                    {assignment.exercise && assignment.exercise.subject
                      ? `${assignment?.totalScore} Points`
                      :assignment.studySet && `${assignment.studySet?.words.length} Points` || ""}
                  </td>
                  <td valign="middle" className="py-3">
                    <Button
                      variant="primary"
                      className="button-style border-0 py-1 px-3"
                      disabled={loading}
                      style={{
                        fontSize: "14px",
                      }}
                      onClick={() =>
                        handleAction(
                          assignment?.submissionStatus,
                          assignment?._id,
                          assignment?.submissionId,
                          assignment?.safeBrowser,
                          assignment?.studySet
                        )
                      }
                    >
                      {assignment?.type === "studySet"
                        ? "View studyset":
                        getButtonLabel(assignment?.submissionStatus)
                      }
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <DynamicModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={handleConfirm}
        heading="Assignment Instructions"
        description="If you switch tabs, minimize, or leave this tab, your assignment will be immediately terminated."
        confirmText="Start"
        denyText="Cancel"
      />
      <SebInstructionsModal
        show={showSEBModal}
        handleClose={() => setShowSEBModal(false)}
        handleDownload={handleDownloadSebFile}
        loadingSeb={loadingSeb}
      />
    </>
  );
};

export default UpcomingDeadlines;
