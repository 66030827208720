import React, { useEffect, useState } from 'react';
import placeholder from "../../../assets/schoolimage.png";
import disabledframe from "../../../assets/disabled.svg";
import { Col, Image, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import BreadCrumbs from 'components/shared/BreadCrumbs';
import ProgressBarCards from 'components/shared/ProgressBarCards';
import UpcomingDeadlines from '../Dashboard/UpcomingDeadlines';
import Statistics from 'views/Admin/user-management/Statistics';
import PageHeading from 'components/shared/PageHeading';
import Groups from '../Dashboard/Groups';
import LatestActivities from 'views/Admin/user-management/LatestActivities';

const ClassStudentDetailPage = () => {
    const [schoolInfo, setSchoolInfo] = useState(null);
    
    const stats = [
        {
            heading: "Total Teachers",
            count: 0,
            value: 60
        },
        {
            heading: "Total Students",
            count: 0,
            value: 78
        },
        {
            heading: "Library Contributions",
            count: 0,
            value: 49
        },
        {
            heading: "Library Contributions",
            count: 0,
            value: 49
        },
        {
            heading: "Library Contributions",
            count: 0,
            value: 49
        },
        {
            heading: "Library Contributions",
            count: 0,
            value: 49
        },
    ];
    const breadcrumbItems = [
        { label: 'User Management', href: '/user-management' },
        { label: "Classes & Groups", active: true }
    ];



    return (
        <>
            <BreadCrumbs items={breadcrumbItems} />
            <div className='text-center my-4'>
                <div className='d-flex justify-content-center align-items-center' style={{
                    position: 'relative', width: "100px", height: "100px", margin: "auto", borderRadius: "60px", overflow: "hidden", border: schoolInfo?.status === "active" ? "5px solid #ea4f50" : "none",
                    boxShadow: "rgba(234, 79, 80, 0.4) 0px 7px 29px 0px"

                }}>
                    {!schoolInfo ? (
                        <Image className='w-100' variant="top" src={schoolInfo?.imageUrl || placeholder} />
                    ) : (
                        <Skeleton circle={true} height={100} width={100} />
                    )}
                    {schoolInfo?.status === "inactive" && (
                        <>
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'black',
                                opacity: 0.5,
                                zIndex: 1
                            }} />
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                backgroundImage: `url(${disabledframe})`,
                                backgroundSize: 'cover',
                                zIndex: 2
                            }} />
                        </>
                    )}
                </div>

                <div className='mt-3' style={{ fontSize: "24px", fontWeight: 700, lineHeight: "27px", color: "#858585" }}>
                    Samantha Williams
                </div>

                <div className='mt-1' style={{ fontSize: "16px", lineHeight: "27px", color: "#858585" }}>
                    student
                </div>

            </div>
            <Row>
                {
                    stats.map((stat, index) => (
                        <Col xs={12} lg={4} key={index}>
                            <ProgressBarCards
                                value={stat.value}
                                heading={stat.heading}
                                count={stat.count}
                            />
                        </Col>
                    ))
                }
            </Row>

            <div>
                {/* <UpcomingDeadlines /> */}
            </div>
            <div>
                <PageHeading heading={"Statistics"} />
                <Statistics />
            </div>
            <div>
                <Row>
                    <Col xs={7}>
                        <LatestActivities />
                    </Col>
                    <Col xs={5}>
                        Tasks
                    </Col>
                </Row>
            </div>
            <div>
                <Groups />
            </div>



        </>
    );
};

export default ClassStudentDetailPage;
