import React from 'react';
import { Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const CustomPagination = ({ currentPage, totalPages, onPageChange, loading = false }) => {

    const getPaginationNumbers = () => {
        const pageButtonsToShow = window.innerWidth < 576 ? 3 : 5;
        const halfRange = Math.floor(pageButtonsToShow / 2);

        let startPage = Math.max(currentPage - halfRange, 1);
        let endPage = Math.min(currentPage + halfRange, totalPages);

        // Ensure we always show the correct number of page buttons
        if (endPage - startPage + 1 < pageButtonsToShow) {
            if (startPage === 1) {
                endPage = Math.min(startPage + pageButtonsToShow - 1, totalPages);
            } else if (endPage === totalPages) {
                startPage = Math.max(endPage - pageButtonsToShow + 1, 1);
            }
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    const paginationNumbers = getPaginationNumbers();

    return (
        <>
            {totalPages !== 1 &&
                <div className="d-flex align-items-center justify-content-end">
                    <button
                        className='me-2 border-0 bg-transparent d-flex align-items-center'
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1 || loading}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
                    </button>

                    <Pagination className="d-flex gap-2 mb-0">
                        {paginationNumbers.map(page => (
                            <Pagination.Item
                                key={page}
                                active={page === currentPage}
                                disabled={loading}
                                onClick={() => onPageChange(page)}
                            >
                                {page}
                            </Pagination.Item>
                        ))}
                    </Pagination>

                    <button
                        className='ms-2 border-0 bg-transparent d-flex align-items-center'
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages || loading}
                    >
                        <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
                    </button>
                </div>
            }
        </>
    );
};

export default CustomPagination;
