import { GetApiData } from "utils/http-client";


export const GetAllSchool = function (page, limit, sortField, sortOrder, filters = {}) {
    const params = new URLSearchParams({ page, limit, sortField, sortOrder });

    Object.keys(filters).forEach((key) => {
        params.append(key, filters[key]);
    });

    return GetApiData(`/school?${params.toString()}`, 'GET', null, true);
}


export const CreateSchool = function (data) {
    return GetApiData(`/school`, 'POST', data, true);
}
export const resetAdminPassword = function (password, id) {
    console.log("Datat",password)
    return GetApiData(`/school/admin/${id}`, 'POST', {password}, true);
}
export const UpdateSchoolStatus = async (id, newStatus) => {
    return await GetApiData(`/school/status/${id}`, 'PUT', { status: newStatus }, true);
};
export const getSchoolDetail = async (id) => {
    return await GetApiData(`/school/${id}`, 'GET', null, true);
};
export const getSchoolStats = async (id) => {
    return await GetApiData(`/school/stats/${id}`, 'GET', null, true);
};
export const UpdateSchoolDetail = async (id, data) => {
    return await GetApiData(`/school/${id}`, 'PUT', data, true);
};
export const deleteSchool = async (id) => {
    return await GetApiData(`/school/${id}`, 'DELETE', null, true);
};

export const deleteStudent = async (id) => {
    return await GetApiData(`/school/student/${id}`, 'DELETE', null, true);
};