import { GetApiData } from "utils/http-client";

export const getUserInfo = async () => {
    return await GetApiData(`/user`, 'GET', null, true);
};
export const getStudentStats = async () => {
    return await GetApiData(`/submission/student/stats`, 'GET', null, true);
};
export const getClassStats = async () => {
    return await GetApiData(`/submission/class/stats`, 'GET', null, true);
};
export const getTeacherStats = async () => {
    return await GetApiData(`/submission/teacher/stats`, 'GET', null, true);
};
export const getUpcomingAssignments = async () => {
    return await GetApiData(`/assignment/upcoming`, 'GET', null, true);
};

export const getClassDetail = async () => {
    return await GetApiData(`/class/me`, 'GET', null, true);
};

export const getGroupOrClassDetail = async (id) => {
    return await GetApiData(`/class/${id}`, 'GET', null, true);
};