import React, { useEffect, useState } from "react";
import { Table, Card, Spinner, Badge, Button, Modal } from "react-bootstrap";
import moment from "moment";
import { useParams } from "react-router-dom";
import CustomPagination from "components/shared/Pagination";
import { getAssignmentAttempts } from "services/Teacher/Assignments.service";
import PageHeading from "components/shared/PageHeading";

const VocabularySubmissions = () => {
  const { assignmentId } = useParams();
  const [loading, setLoading] = useState(true);
  const [assignmentData, setAssignmentData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showModal, setShowModal] = useState(false); // Modal state
  const [selectedAttempt, setSelectedAttempt] = useState(null); // Selected attempt for modal

  // Fetch assignment attempts
  const fetchAttempts = async (page = 1) => {
    setLoading(true);
    try {
      const response = await getAssignmentAttempts(assignmentId, page);
      if (response?.data?.results) {
        setAssignmentData(response.data.results);
        setTotalPages(response.data.results.totalPages || 1);
      }
    } catch (error) {
      console.error("Error fetching attempts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAttempts(currentPage);
  }, [currentPage]);

  // Function to format stage titles
  const getStageTitle = (stageKey) => {
    const { sourceLanguage, targetLanguage } = assignmentData || {};
    switch (stageKey) {
      case "sourceToTarget":
        return `${sourceLanguage} to ${targetLanguage}`;
      case "targetToSource":
        return `${targetLanguage} to ${sourceLanguage}`;
      case "spelling":
        return "Spelling";
      default:
        return stageKey;
    }
  };

  // Function to format status
  const formatStatus = (status) =>
    status === "in-progress" ? "In Progress" : status === "completed"? "Completed":"Pending";

  // Function to handle "View Details" button click
  const handleViewDetails = (attempt) => {
    setSelectedAttempt(attempt);
    setShowModal(true);
  };

  // Function to close the modal
  const handleCloseModal = () => setShowModal(false);

  return (
    <div>
      <PageHeading heading="Assignment Submissions" />

      {loading ? (
        <div className="text-center py-5">
          <Spinner animation="border" variant="primary" />
          <p>Loading submissions...</p>
        </div>
      ) : (
        <>
          {/* Assignment Info Card */}
          <Card className="shadow-sm p-3 mb-4">
            <Card.Body>
              <h5 className="mb-1">
                <strong>Assignment:</strong> {assignmentData?.studySetTitle}
              </h5>
              <p className="mb-1">
                <strong>Assigned By:</strong> {assignmentData?.assignedBy}
              </p>
              <p className="mb-0">
                <strong>Total Attempts:</strong> {assignmentData?.totalAttempts}
              </p>
            </Card.Body>
          </Card>

          {/* Attempts Table */}
          <Table bordered hover responsive className="align-middle">
            <thead className="bg-light">
              <tr>
                <th>Student Name</th>
                <th>Email</th>
                <th>Quiz Attempts</th>
                <th>Current Stage</th>
                <th>Status</th>
                {/* <th>Last Attempt</th> */}
                <th>Completed Words</th>
                <th>Mastered Words</th>
                <th>Stats</th>
              </tr>
            </thead>
            <tbody>
              {assignmentData?.attempts.length > 0 ? (
                assignmentData.attempts.map((attempt) => (
                  <tr key={attempt.studentId}>
                    <td>{attempt.studentName}</td>
                    <td>{attempt.email}</td>
                    <td>{attempt.quizAttempts}</td>
                    <td>
                      <Badge bg="info" className="px-3 py-2">
                        {getStageTitle(attempt.currentStage)}
                      </Badge>
                    </td>
                    <td>
                      <Badge
                        bg={attempt.status === "completed" ? "success" : "warning"}
                        className="px-3 py-2"
                      >
                        {formatStatus(attempt.status)}
                      </Badge>
                    </td>
                    {/* <td>{moment(attempt.lastAttemptAt).format("DD MMM YYYY, h:mm A")}</td> */}
                    <td>{attempt.completedWordsCount}</td>
                    <td>{attempt.masteredWordsCount}</td>
                    <td>
                      <Button
                        variant="primary"
                        className="button-style"
                        size="sm"
                        onClick={() => handleViewDetails(attempt)}
                      >
                        View Details
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="text-center">
                    No attempts found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          {/* Pagination Component */}
          <CustomPagination
            loading={loading}
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        </>
      )}

      {/* Modal for Detailed Stats */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
  <Modal.Header closeButton>
    <Modal.Title>Stage Details</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {selectedAttempt && (
      <div>
        <h5>Student: {selectedAttempt.studentName}</h5>
        <h6>Current Stage: {getStageTitle(selectedAttempt.currentStage)}</h6>
          {Object.keys(selectedAttempt.stages).map((stageKey) => {
            const stage = selectedAttempt.stages[stageKey];
            return (
                <Card className="mb-3 px-3" key={stageKey}>
                <div className="d-flex justify-content-between mt-2">
                <strong>{getStageTitle(stageKey)}</strong>

                    <Badge
                        bg={stage.status === "completed" ? "success" : "warning"}
                        className="px-3 py-2"
                      >
                        {formatStatus(stage.status)}
                      </Badge>
                </div>
                <div className="my-2 text-center">
                  <h5>Highest Score: {stage.score}</h5>{" "}
                </div>
                </Card>
            );
          })}
      </div>
    )}
  </Modal.Body>
</Modal>
    </div>
  );
};

export default VocabularySubmissions;
